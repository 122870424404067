import React from "react";
import Pagination from "./pagination";
import { RenderPostAlt } from "./renderPost";
import { v4 as uuid } from "uuid";

const Component = ({ loading, data, paginationBase, firstExpanded }) => {
    const posts = data?.data || [];
    const meta = data?.meta || {};

    if (!posts.length === 0 && !loading) {
        return (
            <section>
                <div className={styles.posts.empty}>
                    En este momento no hay artículos activos. Disculpa las molestias.
                </div>
            </section>
        );
    }

    return (
        <section className="space-y-10">
            <RenderPosts
                posts={posts}
                firstExpanded={firstExpanded}
                page={meta?.pagination?.page}
            />
            <Pagination meta={meta} base={paginationBase} />
        </section>
    );
};

const RenderPosts = ({ posts, firstExpanded, page }) => {
    return (
        <div className="grid grid-col-s1 md:grid-cols-2 lg:grid-cols-3 gap-10">
            {posts.map((post, index) => {
                return (
                    <RenderPostAlt
                        key={`post-${uuid()}`}
                        data={post}
                        styleClass={
                            firstExpanded && index === 0 && page === 1 ? "featured" : "default"
                        }
                    />
                );
            })}
        </div>
    );
};

const styles = {};

export default Component;
