import React, { useEffect, useState } from "react";
import Layout from "@components/layouts/Layout";
import axios from "axios";
import Seo from "@components/common/Seo";
import { toast } from "react-toastify";
import dayjs from "dayjs";
import RenderPosts from "./components/renderPosts";
import RenderLoading from "./components/loading";

// Importamos el idioma español para dayjs y lo activamos
require("dayjs/locale/es");
dayjs.locale("es");

// Activamos plugin de dayjs para mostrar la fecha en formato relativo
const relativeTime = require("dayjs/plugin/relativeTime");
dayjs.extend(relativeTime);

const Component = ({ pageContext: { category }, page }) => {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        async function fetchData() {
            setLoading(true);

            const toastId = toast.loading("Cargando datos...", {
                position: "bottom-center",
            });

            const rawData = await getPosts({ slug: category.slug, page });
            setData(rawData);

            setLoading(false);
            toast.dismiss(toastId);
        }

        fetchData();
    }, [category.slug, page]);

    return (
        <>
            <RenderSeo category={category} />
            <Layout>
                <RenderBanner category={category} />
                {loading && <RenderLoading />}
                {data && (
                    <div className="container py-20 mx-auto">
                        <RenderPosts
                            loading={loading}
                            data={data}
                            paginationBase={`/blog/categoria/${category.slug}`}
                        />
                    </div>
                )}
            </Layout>
        </>
    );
};

const RenderSeo = ({ category }) => {
    return <Seo title={category.name} description={category.description} image={category.image} />;
};

const RenderBanner = ({ category }) => {
    return (
        <section className={styles.banner.container}>
            <img
                src={category.image}
                alt={category.name}
                className={styles.banner.image}
                style={{ filter: "blur(4px)" }}
            />

            <div className={styles.banner.overlay} />

            <div className="absolute bottom-0 w-full pb-8 z-20 jus">
                <div className="mx-auto container text-white">
                    <h1 className={styles.category.title}>{category.name}</h1>
                    {category.description && (
                        <h2 className={styles.category.description}>{category.description}</h2>
                    )}
                </div>
            </div>
        </section>
    );
};

const getPosts = async ({ page, slug }) => {
    try {
        const url = `/.netlify/functions/posts`;
        const params = {
            page: page || 1,
            category: slug,
        };

        const options = {
            headers: {
                "Content-Type": "application/json",
            },
        };

        const { data } = await axios.post(url, params, options);

        return data?.posts;
    } catch (err) {
        console.error(err);
        return null;
    }
};

const styles = {
    banner: {
        container: "h-116 md:h-96 bg-gray-300 relative overflow-hidden",
        image: "object-cover w-full h-full z-0 absolute top-0 left-0",
        overlay: "w-full h-full top-0 left-0 absolute z-10 bg-black bg-opacity-25",
    },
    category: {
        title: "text-4xl lg:text-5xl font-bold",
        description: "text-xl lg:text-2xl font-light mt-2 lg:mt-4",
    },
};

export default Component;
