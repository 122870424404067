import React from "react";
import { RiRefreshLine } from "react-icons/ri";

const Component = () => {
    return (
        <div className="mx-auto container py-10 px-10 lg:px-0 font-montserrat">
            <div className="text-gray-500 uppercase flex flex-row space-x-5 items-center justify-center">
                <RiRefreshLine className="animate-spin w-10 h-10" />
                <span className="font-semibold text-base lg:text-lg">Cargando el contenido...</span>
            </div>
        </div>
    );
};

export default Component;
